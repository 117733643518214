/* Backdrop */
.GalleryBackdrop {
  @apply absolute top-[-10px] left-[-10px] right-[-10px] bottom-[-10px];
}

@supports (backdrop-filter: blur(12px)) {
  .GalleryBackdrop {
    @apply bg-neutral-shade-secondary;
    backdrop-filter: blur(10px);
  }
}

@supports not (backdrop-filter: blur(12px)) {
  .GalleryBackdrop {
    background-color: #041e25;
    @apply opacity-95;
  }
}

/* Necessary hack to align buttons content, this will be removed after 
this component being moved to Silica using new Buttons */
.Gallery [type~="button"] {
  @apply w-11 h-11;
}

.GalleryHeaderActions [type~="button"] {
  @apply text-white;
}

.Gallery [type~="button"] i {
  @apply m-auto;
}

.Gallery [type~="button"] div {
  @apply hidden;
}

/* Gallery */
.Gallery {
  @apply fixed top-0 left-0 opacity-0 w-screen h-full flex flex-col transition-all duration-300 ease-in-out transform scale-0 select-none z-10;
}

.GalleryOpened {
  @apply opacity-100 transform scale-100;
}

/* Gallery Header */
.GalleryHeader {
  @apply w-full h-16 flex gap-3 justify-between items-center px-4 py-3;
}

/* Gallery Content */
.GalleryContent {
  @apply w-full cursor-pointer flex flex-grow overflow-hidden;
}

/* Gallery Footer */
.GalleryFooter {
  @apply w-full h-32 flex justify-center items-center px-4 py-3;
}

.GalleryHeaderSummary {
  @apply w-full h-full flex gap-3 items-center overflow-hidden z-40;
}

.GalleryHeaderDescription {
  @apply w-full flex flex-col items-start truncate;
}

.GalleryHeaderTitle {
  @apply w-full text-white truncate transition-all duration-500 ease-in-out;
}

.GalleryHeaderSubtitle {
  @apply w-full text-sm text-white truncate;
}

.GalleryHeaderActions {
  @apply flex justify-between items-center gap-1 z-40;
}

.GalleryFileIcon {
  @apply text-2xl text-white;
}

/* Main swiper */
.GallerySwiper {
  @apply w-full h-full;
}

.GallerySwiperSlide [class~="swiper-zoom-container"] {
  @apply w-full md:w-[85%] lg:w-[80%] xl:w-[70%] m-auto;
}

.GallerySwiperSlide img {
  @apply w-full h-full object-contain;
}

/* Thumbnails swiper */
.GallerySwiperThumbnails {
  @apply w-full h-full md:w-[85%] lg:w-[80%] xl:w-[70%] m-auto;
}

.GallerySwiperThumbnailsSlide {
  @apply max-w-max;
}

.GallerySwiperThumbnailsSlide img {
  @apply w-13 h-13 md:w-18 md:h-18 object-cover;
}

/* Navigation Button */
.NavigationButton {
  @apply absolute w-11 h-11 transform translate-y-1/2 top-1/2 cursor-pointer z-40 text-3xl flex justify-center items-center;
  /* FIX: remove !important after avoiding overrides navigation buttons styling */
  @apply bg-neutral-shade-secondary rounded-full !important;
}

.MenuItemLabel {
  @apply ml-2;
}

.MenuItemHighlighted {
  @apply text-system-error-40;
}

.MainDeleteButton:hover {
  @apply bg-system-error-30;
}
