:root {
  --side-bar-width: 375px;
}
ion-split-pane {
  --side-min-width: 375px;
  --side-max-width: 375px;
}

ion-list {
  --ion-toolbar-background: transparent;
  --ion-toolbar-color: var(--ion-text-color);
}

ion-item-divider {
  --background: var(--ion-background-color);
  --color: var(--urbint-transparencies-black-88);
}

ion-item {
  --detail-icon-color: var(--urbint-transparencies-black-66);
  --detail-icon-font-size: 20px;
  --detail-icon-opacity: 1;
}

ion-button.button-disabled {
  opacity: 0.33;
}

ion-header {
  --ion-toolbar-background: #1e3340;
  --ion-item-background: #1e3340;
  --ion-toolbar-color: var(--urbint-white);
}

ion-header ion-skeleton-text {
  --background-rgb: var(--urbint-white-rgb);
  --border-radius: var(--urbint-border-radius);
}

.sc-ion-label-md-s > p {
  color: var(--urbint-transparencies-black-66);
}

html.plt-mobile ion-app {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  /* covers loading logo */
  background: white;
}

@media (max-width: 340px) {
  .mobile-toolbar ion-tab-button {
    max-width: 65px;
  }
}
