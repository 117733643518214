:root {
  --urbint-black: #000000;
  --urbint-black-rgb: 0, 0, 0;
  --urbint-white: #ffffff;
  --urbint-white-rgb: 255, 255, 255;

  /* Yellows */

  --urbint-yellow-50-rgb: 255, 249, 224;
  --urbint-yellow-50: #fff9e0;
  --urbint-yellow-100-rgb: 255, 226, 179;
  --urbint-yellow-100: #ffe2b3;
  --urbint-yellow-200-rgb: 255, 207, 130;
  --urbint-yellow-200: #ffcf82;
  --urbint-yellow-300-rgb: 255, 188, 79;
  --urbint-yellow-300: #ffbc4f;
  --urbint-yellow-400-rgb: 255, 173, 40;
  --urbint-yellow-400: #ffad28;
  --urbint-yellow-500-rgb: 255, 160, 7;
  --urbint-yellow-500: #ffa007;
  --urbint-yellow-600-rgb: 242, 137, 0;
  --urbint-yellow-600: #f28900;
  --urbint-yellow-700-rgb: 229, 122, 0;
  --urbint-yellow-700: #e57a00;
  --urbint-yellow-800-rgb: 217, 108, 0;
  --urbint-yellow-800: #d96c00;
  --urbint-yellow-900-rgb: 199, 93, 0;
  --urbint-yellow-900: #c75d00;

  /* Greens */

  --urbint-green-50: #e6f6ef;
  --urbint-green-100-rgb: 194, 233, 214;
  --urbint-green-100: #c2e9d6;
  --urbint-green-200-rgb: 154, 219, 189;
  --urbint-green-200: #9adbbd;
  --urbint-green-300-rgb: 109, 206, 163;
  --urbint-green-300: #6dcea3;
  --urbint-green-400-rgb: 71, 195, 143;
  --urbint-green-400: #47c38f;
  --urbint-green-500-rgb: 10, 184, 123;
  --urbint-green-500: #0ab87b;
  --urbint-green-600-rgb: 0, 168, 112;
  --urbint-green-600: #00a870;
  --urbint-green-700-rgb: 0, 149, 98;
  --urbint-green-700: #009562;
  --urbint-green-800-rgb: 0, 132, 86;
  --urbint-green-800: #008456;
  --urbint-green-900-rgb: 0, 100, 64;
  --urbint-green-900: #006440;

  /* Reds */

  --urbint-red-50-rgb: 255, 235, 238;
  --urbint-red-50: #ffebee;
  --urbint-red-100-rgb: 255, 205, 210;
  --urbint-red-100: #ffcdd2;
  --urbint-red-200-rgb: 239, 154, 154;
  --urbint-red-200: #ef9a9a;
  --urbint-red-300-rgb: 229, 115, 115;
  --urbint-red-300: #e57373;
  --urbint-red-400-rgb: 239, 83, 80;
  --urbint-red-400: #ef5350;
  --urbint-red-500-rgb: 244, 67, 54;
  --urbint-red-500: #f44336;
  --urbint-red-600-rgb: 229, 57, 53;
  --urbint-red-600: #e53935;
  --urbint-red-700-rgb: 211, 47, 47;
  --urbint-red-700: #d32f2f;
  --urbint-red-800-rgb: 188, 21, 21;
  --urbint-red-800: #bc1515;
  --urbint-red-900-rgb: 169, 10, 10;
  --urbint-red-900: #a90a0a;

  /* Blues */

  --urbint-blue-50-rgb: 226, 241, 252;
  --urbint-blue-50: #e2f1fc;
  --urbint-blue-100-rgb: 185, 220, 249;
  --urbint-blue-100: #b9dcf9;
  --urbint-blue-200-rgb: 140, 199, 246;
  --urbint-blue-200: #8cc7f6;
  --urbint-blue-300-rgb: 94, 177, 242;
  --urbint-blue-300: #5eb1f2;
  --urbint-blue-400-rgb: 56, 160, 240;
  --urbint-blue-400: #38a0f0;
  --urbint-blue-500-rgb: 1, 144, 238;
  --urbint-blue-500: #0190ee;
  --urbint-blue-600-rgb: 0, 131, 224;
  --urbint-blue-600: #0083e0;
  --urbint-blue-700-rgb: 0, 113, 205;
  --urbint-blue-700: #0071cd;
  --urbint-blue-800-rgb: 0, 96, 187;
  --urbint-blue-800: #0060bb;
  --urbint-blue-900-rgb: 0, 66, 157;
  --urbint-blue-900: #00429d;

  /* Bluegray */

  --urbint-bluegray-50-rgb: 252, 253, 252;
  --urbint-bluegray-50: #fcfdfc;
  --urbint-bluegray-100-rgb: 242, 245, 245;
  --urbint-bluegray-100: #f2f5f5;
  --urbint-bluegray-200-rgb: 230, 237, 238;
  --urbint-bluegray-200: #e6edee;
  --urbint-bluegray-300-rgb: 171, 190, 194;
  --urbint-bluegray-300: #abbec2;
  --urbint-bluegray-400-rgb: 147, 169, 173;
  --urbint-bluegray-400: #93a9ad;
  --urbint-bluegray-500-rgb: 118, 143, 148;
  --urbint-bluegray-500: #768f94;
  --urbint-bluegray-600-rgb: 82, 105, 108;
  --urbint-bluegray-600: #52696c;
  --urbint-bluegray-700-rgb: 67, 85, 88;
  --urbint-bluegray-700: #435558;
  --urbint-bluegray-800-rgb: 54, 66, 68;
  --urbint-bluegray-800: #364244;
  --urbint-bluegray-900-rgb: 37, 45, 46;
  --urbint-bluegray-900: #252d2e;

  /* Gray */
  --urbint-gray-100: #eeeeee;
  --urbint-gray-100-rgb: 238, 238, 238;
  --urbint-gray-200: #e0e0e0;
  --urbint-gray-200-rgb: 224, 224, 224;
  --urbint-gray-300: #bdbdbd;
  --urbint-gray-300-rgb: 189, 189, 189;
  --urbint-gray-400: #9e9e9e;
  --urbint-gray-400-rgb: 158, 158, 158;
  --urbint-gray-500: #757575;
  --urbint-gray-500-rgb: 117, 117, 117;
  --urbint-gray-600: #616161;
  --urbint-gray-600-rgb: 97, 97, 97;
  --urbint-gray-700: #424242;
  --urbint-gray-700-rgb: 66, 66, 66;
  --urbint-gray-800: #212121;
  --urbint-gray-800-rgb: 33, 33, 33;

  /* Brand colors */
  --urbint-brand-color: #00a0cc;
  --urbint-brand-color-rgb: 0, 160, 204;
  --urbint-brand-dark-color: #1e3340;
  --urbint-brand-dark-color-rgb: 30, 51, 64;

  /* Transparencies */
  --urbint-transparencies-black-0: rgba(0, 0, 0, 0);
  --urbint-transparencies-black-11: rgba(0, 0, 0, 0.11);
  --urbint-transparencies-black-22: rgba(0, 0, 0, 0.22);
  --urbint-transparencies-black-33: rgba(0, 0, 0, 0.33);
  --urbint-transparencies-black-6: rgba(0, 0, 0, 0.06);
  --urbint-transparencies-black-66: rgba(0, 0, 0, 0.66);
  --urbint-transparencies-black-88: rgba(0, 0, 0, 0.88);

  /* spacing */
  --urbint-spacing-xxs: 0.125rem; /* 2px */
  --urbint-spacing-xs: 0.25rem; /* 4px */
  --urbint-spacing-sm: 0.5rem; /* 8px */
  --urbint-spacing-md: 1rem; /* 16px */
  --urbint-spacing-lg: 2rem; /* 32px */
  --urbint-spacing-xl: 3rem; /* 48px */
  --urbint-spacing-xxl: 4rem; /* 64px */

  /* shadows */
  --urbint-elevation: 0px 8px 10px rgba(0, 0, 0, 0.07),
    0px 3px 14px rgba(0, 0, 0, 0.06), 0px 5px 5px rgba(0, 0, 0, 0.1);

  /* misc */
  --urbint-border-radius-sm: 0.25rem;
  --urbint-border-radius: 0.5rem;
}
