.urbint-loading-indicator {
  aspect-ratio: 1 / 1;
  display: inline-block;
  height: 100%;
  position: relative;
  display: flex;
}

.urbint-loading-indicator div {
  border-radius: 50%;
  border-style: solid;
  height: 100%;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.urbint-loading-indicator div:nth-child(1) {
  animation: urb-pulse 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.urbint-loading-indicator div:nth-child(2),
.urbint-loading-indicator div:nth-child(3) {
  animation: urbint-loading-indicator 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.urbint-loading-indicator div:nth-child(3) {
  animation-delay: -1s;
}

@keyframes urb-pulse {
  0% {
    opacity: 0.7;
    transform: scale(0.2);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.19);
  }

  100% {
    opacity: 0.7;
    transform: scale(0.2);
  }
}

@keyframes urbint-loading-indicator {
  0% {
    opacity: 1;
    transform: scale(0.25);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
