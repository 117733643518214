:root {
  --ion-background-color: var(--urbint-white);
  --ion-background-color-rgb: var(--urbint-white-rgb);

  --ion-text-color: var(--urbint-transparencies-black-88);
  --ion-text-color-rgb: 31, 31, 31;

  --ion-color-step-50: #e5e5e6;
  --ion-color-step-100: #dbdcde;
  --ion-color-step-150: #d2d3d6;
  --ion-color-step-200: #c9cace;
  --ion-color-step-250: #bfc1c6;
  --ion-color-step-300: #b6b8be;
  --ion-color-step-350: #adafb6;
  --ion-color-step-400: #a3a6ae;
  --ion-color-step-450: #9a9da6;
  --ion-color-step-500: #91959e;
  --ion-color-step-550: #878c95;
  --ion-color-step-600: #7e838d;
  --ion-color-step-650: #747a85;
  --ion-color-step-700: #6b717d;
  --ion-color-step-750: #626875;
  --ion-color-step-800: #585f6d;
  --ion-color-step-850: #4f5665;
  --ion-color-step-900: #464d5d;
  --ion-color-step-950: #3c4455;

  /* overrides */
  --ion-color-primary: var(--urbint-blue-600);
  --ion-color-primary-rgb: var(--urbint-blue-600-rgb);
  --ion-color-primary-contrast: var(--urbint-white);
  --ion-color-primary-contrast-rgb: var(--urbint-white-rgb);
  --ion-color-primary-shade: var(--urbint-blue-800);
  --ion-color-primary-tint: var(--urbint-blue-400);
  /* --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff; */
  --ion-color-success: var(--urbint-green-600);
  --ion-color-success-rgb: var(--urbint-green-600-rgb);
  --ion-color-success-contrast: var(--urbint-white);
  --ion-color-success-contrast-rgb: var(--urbint-white-rgb);
  --ion-color-success-shade: var(--urbint-green-800);
  --ion-color-success-tint: var(--urbint-green-400);
  --ion-color-warning: var(--urbint-yellow-500);
  --ion-color-warning-rgb: var(--urbint-yellow-500-rgb);
  --ion-color-warning-contrast: var(--urbint-white);
  --ion-color-warning-contrast-rgb: var(--urbint-white-rgb);
  --ion-color-warning-shade: var(--urbint-yellow-700);
  --ion-color-warning-tint: var(--urbint-yellow-300);
  --ion-color-danger: var(--urbint-red-600);
  --ion-color-danger-rgb: var(--urbint-red-600-rgb);
  --ion-color-danger-contrast: var(--urbint-white);
  --ion-color-danger-contrast-rgb: var(--urbint-white-rgb);
  --ion-color-danger-shade: var(--urbint-red-800);
  --ion-color-danger-tint: var(--urbint-red-400);
  --ion-color-dark: var(--urbint-bluegray-800);
  --ion-color-dark-rgb: var(--urbint-bluegray-800-rgb);
  --ion-color-dark-contrast: var(--urbint-white);
  --ion-color-dark-contrast-rgb: var(--urbint-white-rgb);
  --ion-color-dark-shade: var(--urbint-bluegray-900);
  --ion-color-dark-tint: var(--urbint-bluegray-700);
  --ion-color-medium: var(--urbint-bluegray-400);
  --ion-color-medium-rgb: var(--urbint-bluegray-400-rgb);
  --ion-color-medium-contrast: var(--urbint-white);
  --ion-color-medium-contrast-rgb: var(--urbint-white-rgb);
  --ion-color-medium-shade: var(--urbint-bluegray-500);
  --ion-color-medium-tint: var(--urbint-bluegray-300);
  --ion-color-light: var(--urbint-bluegray-100);
  --ion-color-light-rgb: var(--urbint-bluegray-100-rgb);
  --ion-color-light-contrast: var(--urbint-black);
  --ion-color-light-contrast-rgb: var(--urbint-black-rgb);
  --ion-color-light-shade: var(--urbint-bluegray-200);
  --ion-color-light-tint: var(--urbint-bluegray-50);
  /* end overrides */
}

/* TODO: Remove this, when seperating out NavBar */
.popover-content {
  --width: 375px;
}
