@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .menu-panel {
    @apply bg-white;
    @apply border;
    @apply drop-shadow;
    @apply flex;
    @apply relative;
    @apply rounded-md;
    @apply shadow-20;
    max-width: calc(100vw - 1rem);
    min-height: 0.5rem;
    min-width: 75px;
  }

  .menu-panel > [data-popper-arrow],
  .menu-panel > [data-popper-arrow]::before {
    @apply w-2 h-2;
    @apply absolute;
    background: inherit;
  }

  .menu-panel > [data-popper-arrow] {
    @apply invisible;
    z-index: -1;
  }

  .menu-panel > [data-popper-arrow]::before {
    @apply visible rotate-45;
    @apply bg-white;
    @apply border;
    content: "";
  }

  .menu-panel[data-placement^="top"] > [data-popper-arrow] {
    @apply -bottom-1;
  }

  .menu-panel[data-placement^="top"] > [data-popper-arrow]::before {
    @apply border-t-white border-l-white;
  }

  .menu-panel[data-placement^="bottom"] > [data-popper-arrow] {
    @apply -top-1;
  }

  .menu-panel[data-placement^="bottom"] > [data-popper-arrow]::before {
    @apply border-b-white border-r-white;
  }

  .menu-panel[data-placement^="left"] > [data-popper-arrow] {
    @apply -right-1;
  }

  .menu-panel[data-placement^="left"] > [data-popper-arrow]::before {
    @apply border-l-white border-b-white;
  }

  .menu-panel[data-placement^="right"] > [data-popper-arrow] {
    @apply -left-1;
  }
  .menu-panel[data-placement^="right"] > [data-popper-arrow]::before {
    @apply border-r-white border-t-white;
  }
}
