.locate-me-animation {
  animation: locate-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes locate-pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
