.mapboxgl-popup-content {
  background: transparent !important;
  border-radius: none !important;
  padding-bottom: 50px !important;
  box-shadow: none !important;
}

.distance-popup .mapboxgl-popup-content {
  pointer-events: none !important;
}

.mapboxgl-popup-tip {
  display: none;
}
