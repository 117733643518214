@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants {
    .icon-semibold {
      --ionicon-stroke-width: 4rem;
    }
    .icon-bold {
      --ionicon-stroke-width: 6rem;
    }
  }
}

/* https://github.com/tailwindlabs/tailwindcss/discussions/2394 */
@layer utilities {
  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
