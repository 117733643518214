.urbint--map-controls-wrapper {
  pointer-events: none;
}

.urbint--map-controls-wrapper * {
  pointer-events: auto;
}

.mapboxgl-ctrl-attrib-inner a:nth-child(n + 3) {
  display: none;
}
